import React from "react";
import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";
import PropTypes from "prop-types";
import { configValue } from "./config";

const MapStyles = [
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        saturation: "-100"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "labels.text",
    stylers: [
      {
        color: "#545454"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "labels.text.stroke",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        saturation: "-87"
      },
      {
        lightness: "-40"
      },
      {
        color: "#ffffff"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#f0f0f0"
      },
      {
        saturation: "-22"
      },
      {
        lightness: "-16"
      }
    ]
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "on"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "geometry.stroke",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        saturation: "-52"
      },
      {
        hue: "#00e4ff"
      },
      {
        lightness: "-16"
      }
    ]
  }
];

const SitesMap = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?${
      configValue("GOOGLE_MAPS_API_KEY", false)
        ? `key=${configValue("GOOGLE_MAPS_API_KEY", false)}&`
        : ""
    }v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100vh ` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(({ sites, onWindowDismiss, selected, handleClick, modalCallback }) => (
  <GoogleMap
    defaultZoom={6}
    defaultOptions={{
      fullscreenControl: false,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: window.google.maps.ControlPosition.TOP_CENTER
      },
      streetViewControl: false,
      minZoom: 3,
      styles: MapStyles
    }}
    defaultCenter={{ lat: -25.2744, lng: 133.7751 }}
  >
    {sites
      ? sites.map((s, index) => (
          <Marker
            key={index}
            icon={`${process.env.PUBLIC_URL}/${s.Phase}.png`}
            position={{ lat: s.Lat, lng: s.Lng }}
            onClick={() => handleClick(s)}
          >
            {selected === s ? (
              <InfoWindow onCloseClick={onWindowDismiss}>
                <div className="p-2">
                  <h5>{s.School}</h5>
                  <div
                    className="btn btn-sm btn-primary"
                    onClick={modalCallback}
                  >
                    Show details
                  </div>
                </div>
              </InfoWindow>
            ) : null}
          </Marker>
        ))
      : null}
  </GoogleMap>
));

SitesMap.proTypes = {
  sites: PropTypes.array.isRequired
};

export default SitesMap;
