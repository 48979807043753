import React, { useState } from 'react';
import { compose } from 'recompose';
import Logo from "../../logo.svg";
import Downer from "../../Downer.jpg";
import { withFirebase } from '../Firebase';
import Map from "../../Map";

const SignInPage = (props) => (
  <SignInForm loggedIn={props.loggedIn} />
);

const SignInFormBase = (props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const { loggedIn } =  props;

  const onSubmit = event => {
    props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        loggedIn();
      })
      .catch(error => {
        setError(error);
      });

    event.preventDefault();
  };

  const onChangeEmail = event => {
    setEmail(event.target.value);
  };

  const onChangePassword = event => {
    setPassword(event.target.value);
  };

  const isInvalid = password === '' || email === '';

  return (
    <div className="text-center h-100">
      <div className="d-flex h-100 justify-content-center align-items-center">
          <div
            className="p-3 rounded shadow-lg"
            style={{ width: "30em", zIndex: "100", backgroundColor: "#FFFFFF" }}
          >
            <div className="d-flex justify-content-between flex-row align-items-end">
              <img
                className="img-fluid"
                alt="Solar Schools"
                src={Logo}
                style={{ fill: "#EA7525", width: "60%" }}
              />
              <img
                className="img-fluid pl-3 pb-2"
                alt="Downer"
                src={Downer}
                style={{ width: "40%" }}
              />
            </div>
            <hr />
            <h2 className="text-muted text-center font-weight-bold px-4 mb-3">
              ACES Rollout
            </h2>
            <h4 className="my-3 text-uppercase text-primary font-weight-bold">
              Log in to your account
            </h4>
            <div className="d-flex flex-column">
              <div className="flex-grow-1 btn-group-sm dropdown">
                <form onSubmit={onSubmit}>
                  <input
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                    className="form-control mb-3"
                    type="text"
                    placeholder="Email Address"
                  />
                  <input
                    name="password"
                    value={password}
                    onChange={onChangePassword}
                    className="form-control my-3"
                    type="password"
                    placeholder="Password"
                  />
                  <div className="btn-link h4 font-weight-bold" style={{cursor: "pointer"}} disabled={isInvalid} onClick={onSubmit}>
                    Log In
                  </div>
                  {error && <p className="text-muted">{error.message}</p>}
                </form>
              </div>
          </div>
        </div>
      </div>
      <div className="position-absolute h-100 w-100" style={{top: "0px", left: "0px", filter: "blur(8px)", pointerEvents: "none"}} >
        <Map />
      </div>
    </div>
  );
}

const SignInForm = compose(
  withFirebase,
)(SignInFormBase);

export default SignInPage;

export { SignInForm };