import app from 'firebase/app';
// import FB from 'firebase';
import 'firebase/auth';
import 'firebase/database';

const config = {
    apiKey: process.env["REACT_APP_FIREBASE_API_KEY"],
    authDomain: process.env["REACT_APP_FIREBASE_AUTH_DOMAIN"],
    databaseURL: process.env["REACT_APP_FIREBASE_DATABASE_URL"]
};

class Firebase {
    constructor() {
        app.initializeApp(config);
        // FB.database.enableLogging(true);
        this.auth = app.auth();
        this.db = app.database();
        this.app = app;
    }

    // *** Auth API ***
    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    // *** User API ***
    user = uid => this.db.ref(`users/${uid}`);

    users = () => this.db.ref('users');

    // *** Data API ***
    ref = () => this.db.ref('/');
}

export default Firebase;