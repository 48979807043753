import React, { useState, useContext } from "react";
import "./App.scss";
import { FirebaseContext } from "./components/Firebase";
import Login from "./components/Login/Login";
import LoadData from "./containers/LoadData";

//TODO sort sites by annualConsumptionReadings
const App = () => {
  const Firebase = useContext(FirebaseContext);
  const [user, setUser] = useState(false);

  const handleLoggedIn = () => {
    setUser(Firebase.auth.currentUser);
  }

  if (user) {
    return <LoadData />
  } else {
    return (
      <Login loggedIn={handleLoggedIn}/>
    );
  }
}

export default App;
