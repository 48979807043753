import React, { useEffect, useState, useContext } from 'react';
import { FirebaseContext } from '../../components/Firebase';
import Content from '../../components/Content/Content';

const LoadData = () => {
  const [reducedData, ] = useState([]);
  const [loading, setLoading] = useState(true);
  const Firebase = useContext(FirebaseContext);
  const ref = Firebase.ref();

  useEffect(() => {
    ref.on('value', (snap) => {
      snap.forEach((snap) => {
        let site = snap.val();
        const s = {
          School: site.School,
          Region: site.Region,
          Scope: site.Scope,
          Phase: site.Phase,
          Population: site.Population,
          Lat: site.Lat,
          Lng: site.Lng,
          Heritage: site.Heritage,
          "Led Upgrade": site["Led Upgrade"],
          "Energy  Mgt Framework": site["Energy  Mgt Framework"],
          "Electricity  Safety Upgrade": site["Electricity  Safety Upgrade"],
          "Floor Area": site["Floor Area"]
        };
        const meter = {
          NMI: site.NMI,
          "Tariff  $/kWh": site.Tariff,
          "Demand  $/KVA/month": site["Demand"],
          "kWh p.a.": site["kWh pa"],
          "Avg. Max KVA": site["Avg Max KVA"],
          "Metered Demand Date/Time": site["Metered Demand DateTime"],
          "Metered Demand": site["Metered Demand"],
          "Power Factor": site["Power Factor"],
          "Energy  Costs": site["Energy  Costs"],
          "Demand Costs": site["Demand Costs"],
          "Est. Costs  p.a.": site["Est Costs  pa"],
          "Solar (kW)": site["Solar (kW)"],
          "Solar kWh p.a.": site["Solar kWh pa"],
          "Solar Savings p.a.": site["Solar Savings pa"],
          "Payback  (years)": site["Payback  (years)"]
        };
        const index = reducedData.findIndex(
          e => e.School === s.School && e.Lat === s.Lat && e.Lng === s.Lng
        );
        // Filter out all other regions -- requested by Rob
        if (s.Region === "Far North Queensland" || s.Region === "North Coast" || s.Region === "North Queensland") {
          if (index === -1) {
            s.meters = [meter];
            reducedData.push(s);
          } else {
            reducedData[index].meters.push(meter);
          }
        }
      })
      setLoading(false);
    });
  }, [])


  const [regions, setRegions] = useState(false);
  const [categories, setCategories] = useState(false);
  const [phases, setPhases] = useState(false);

  useEffect(() => {
    if (!loading) {
      setRegions([...new Set(reducedData.map(item => item.Region))]);
      setCategories([...new Set(reducedData.map(item => item.Scope))]);
      setPhases([...new Set(reducedData.map(item => item.Phase))].sort());
    }
  }, [loading]);


  return (regions && categories && phases && Firebase.auth.currentUser) ? (
      <Content regions={regions} categories={categories} phases={phases} reducedData={reducedData}/>
    ) : null
}

export default LoadData;
