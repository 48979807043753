import React, { Component } from 'react';
import Map from "../../Map";
import Filter from "../../Filter";
import { Modal, ModalBody, Collapse } from "reactstrap";
import Logo from "../../logo.svg";
import Downer from "../../Downer.jpg";
import { FirebaseContext } from '../Firebase';
import { configValue } from "../../config.js";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyShown: true,
      searchTerm: "",
      searchResults: [],
      modal: false,
      selectedSite: false,
      sites: [],
      regionDropDownActive: false,
      regions: props.regions,
      selectedRegions: [],
      categoryDropDownActive: false,
      categories: props.categories,
      selectedCategories: [],
      phasesDropDownActive: false,
      phases: props.phases,
      selectedPhases: [],
      annualConsumptionReadings: [],
      consumptionDropDownActive: false,
      Consumption: 0,
      consumptionRanges: {
        veryHigh: 0,
        high: 0,
        medium: 0,
        low: 0,
        veryLow: 0
      }
    };
  }

  toggleRegionDropDown = () => {
    this.setState({
      regionDropDownActive: !this.state.regionDropDownActive
    });
  };

  handleRegionSelect = ({ target: { innerText } }) => {
    let { selectedRegions } = this.state;
    const isSelected = selectedRegions.includes(innerText);
    if (isSelected) {
      selectedRegions = selectedRegions.filter(i => i !== innerText);
    } else {
      selectedRegions.push(innerText);
    }
    const mod = this;
    new Promise((resolve, reject) => {
      mod.setState(
        {
          regionDropDownActive: !this.state.regionDropDownActive,
          selectedRegions
        },
        resolve()
      );
    }).then(() => {
      mod.updateSites();
    });
  };

  toggleCategoryDropDown = () => {
    this.setState({
      categoryDropDownActive: !this.state.categoryDropDownActive
    });
  };

  handleCategorySelect = ({ target: { innerText } }) => {
    let { selectedCategories } = this.state;
    const isSelected = selectedCategories.includes(innerText);
    if (isSelected) {
      selectedCategories = selectedCategories.filter(i => i !== innerText);
    } else {
      selectedCategories.push(innerText);
    }

    const mod = this;
    new Promise((resolve, reject) => {
      mod.setState(
        {
          categoryDropDownActive: !this.state.categoryDropDownActive,
          selectedCategories
        },
        resolve()
      );
    }).then(() => {
      mod.updateSites();
    });
  };

  togglePhasesDropDown = () => {
    this.setState({
      phasesDropDownActive: !this.state.phasesDropDownActive
    });
  };

  handlePhasesSelect = ({ target: { innerText } }) => {
    let { selectedPhases } = this.state;
    const isSelected = selectedPhases.includes(innerText);
    if (isSelected) {
      selectedPhases = selectedPhases.filter(i => i !== innerText);
    } else {
      selectedPhases.push(innerText);
    }

    const mod = this;
    new Promise((resolve, reject) => {
      mod.setState(
        {
          phasesDropDownActive: !this.state.phasesDropDownActive,
          selectedPhases
        },
        resolve()
      );
    }).then(() => {
      mod.updateSites();
    });
  };

  handleSearchChange = ({ target: { value } }) => {
    const mod = this;
    new Promise((resolve, reject) => {
      mod.setState({ searchTerm: value }, resolve());
    }).then(() => {
      const resultSites = mod.state.sites.filter(s =>
        s.School.toLowerCase().includes(mod.state.searchTerm.toLowerCase())
      );
      const results = resultSites.slice(0, 5);
      mod.setState({ searchResults: results });
    });
  };

  // filterByConsumptions = s => {
  //   // get the meters from the School and return if one of them has "kWh p.a." over consumption
  //   let isValid = false;
  //   s.meters.forEach(m => {
  //     if (m["kWh p.a."] > this.state.Consumption) {
  //       isValid = true;
  //     }
  //   });
  //   return isValid;
  // };

  calculateSites = () => {
    const { selectedCategories, selectedRegions, selectedPhases } = this.state;
    try {
      return this.props.reducedData
        .filter(
          s =>
            selectedRegions.length ? selectedRegions.includes(s.Region) : true
        )
        .filter(
          s =>
            selectedCategories.length
              ? selectedCategories.includes(s.Scope)
              : true
        )
        .filter(
          s => (selectedPhases.length ? selectedPhases.includes(s.Phase) : true)
        );
    } catch {
      return null;
    }
  };

  changePhaseOnState = event => {
    const { selectedSite, sites } = this.state;
    const newSites = sites.map(e => e);
    const index = newSites.findIndex(
      e =>
        e.School === selectedSite.School &&
        e.Region === selectedSite.Region &&
        e.Lat === selectedSite.Lat &&
        e.Lng === selectedSite.Lng
    );

    // Update database ---
    // For every school that has the same name as the selected name
    const ref = this.context.ref();
    ref.orderByChild('School').equalTo(selectedSite.School).on('value', (snap) => {
      snap.forEach((child) => {
        let site = child.val();
        // If the LAT and LNG match the selected site - update the value
        if (site.Lat === selectedSite.Lat && site.Lng === selectedSite.Lng) {
          try {
            child.ref.update({ Phase: event.target.value })
          } catch {
            console.error("Failed to update database")
          }
        }
      });
    });

    // Update locally ---
    if (index) {
      newSites[index].Phase = event.target.value;
      this.setState({ sites: newSites, selectedSite: newSites[index] });
    }
  };

  updateSites = () => {
    const sites = this.calculateSites();
    this.setState({ sites });
  };

  componentDidMount = () => {
    this.updateSites();
  };

  render() {
    return (
      <div className="text-center">
        <div
          className="control-panel rounded shadow-lg"
          style={{ width: "20em" }}
        >
          <div className="d-flex justify-content-between flex-row align-items-end">
            <img
              className="img-fluid"
              alt="Solar Schools"
              src={Logo}
              style={{ fill: "#EA7525", width: "60%" }}
            />
            <img
              className="img-fluid pl-3 pb-1"
              alt="Downer"
              src={Downer}
              style={{ width: "40%" }}
            />
          </div>
          <hr />
          <h2 className="text-muted text-center font-weight-bold px-4 mb-3">
            ACES Rollout
          </h2>
          <h4 className="mt-2 text-uppercase text-primary font-weight-bold">
            Filter Schools
          </h4>
          <p className="text-muted">
            Click to toggle a filter, when no filters are selected all items
            will be displayed.
          </p>
          <div className="d-flex flex-column mb-3">
            <div className="mr-2 flex-grow-1 mb-3 btn-group-sm dropdown">
              <input
                type="text"
                className="form-control"
                placeholder="Search for a school"
                value={this.state.searchTerm}
                onChange={this.handleSearchChange}
              />
              {this.state.searchResults.length > 0 && this.state.searchTerm ? (
                <div
                  className="position-absolute card bg-white"
                  style={{ zIndex: "22" }}
                >
                  {this.state.searchResults.map((r, index) => (
                    <div
                      key={index}
                      onClick={() =>
                        this.setState({
                          selectedSite: r,
                          searchResults: [],
                          searchTerm: ""
                        })
                      }
                      className="btn btn-link"
                    >
                      {r.School}
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <Filter
              className="flex-grow-1 mb-3"
              title="Region"
              data={this.state.regions}
              toggle={this.toggleRegionDropDown}
              selected={this.state.selectedRegions}
              handleSelect={this.handleRegionSelect}
              handleActive={this.state.regionDropDownActive}
            />
            <Filter
              className="flex-grow-1 mb-3"
              title="Category"
              data={this.state.categories}
              toggle={this.toggleCategoryDropDown}
              selected={this.state.selectedCategories}
              handleSelect={this.handleCategorySelect}
              handleActive={this.state.categoryDropDownActive}
            />
            <Filter
              className="flex-grow-1 mb-3"
              title="Phases"
              data={this.state.phases}
              toggle={this.togglePhasesDropDown}
              selected={this.state.selectedPhases}
              handleSelect={this.handlePhasesSelect}
              handleActive={this.state.phasesDropDownActive}
            />
          </div>
          <h4
            className={`position-relative mt-2 mr-2 text-uppercase text-primary font-weight-bold special-caret__${
              this.state.keyShown ? "up" : "down"
            }`}
            style={{ cursor: "pointer" }}
            onClick={() => {
              this.setState({ keyShown: !this.state.keyShown });
            }}
          >
            Key
          </h4>
          <Collapse isOpen={this.state.keyShown}>
            <div className="d-flex flex-column">
              <div className="text-muted">
                <img
                  alt="Phase 1"
                  className="img-fluid w-30 mr-4"
                  src={`${process.env.PUBLIC_URL}/Phase 1.png`}
                />
                Phase 1
              </div>
              <div className="text-muted">
                <img
                  alt="Phase 1"
                  className="img-fluid w-30 mr-4"
                  src={`${process.env.PUBLIC_URL}/Phase 2.png`}
                />
                Phase 2
              </div>
              <div className="text-muted">
                <img
                  alt="Phase 1"
                  className="img-fluid w-30 mr-4"
                  src={`${process.env.PUBLIC_URL}/Phase 3.png`}
                />
                Phase 3
              </div>
              <div className="text-muted">
                <img
                  alt="Phase 1"
                  className="img-fluid w-30 mr-4"
                  src={`${process.env.PUBLIC_URL}/Scope 2.png`}
                />
                Scope 2
              </div>
            </div>
          </Collapse>
        </div>
        <div className="">
          <Map
            sites={this.state.sites}
            selected={this.state.selectedSite}
            handleClick={s => this.setState({ selectedSite: s })}
            modalCallback={() => this.setState({ modal: true })}
            onWindowDismiss={() => this.setState({ selectedSite: false })}
          />
        </div>
        <Modal
          // size="lg"
          isOpen={this.state.modal}
          toggle={() => this.setState({ modal: !this.state.modal })}
        >
          <ModalBody className="p-4">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-8">
                    <h3 className="text-primary">View Data</h3>
                  </div>
                  <div className="col-4 text-right">
                    <svg
                      data-name="Layer 2"
                      viewBox="0 0 56 56"
                      className="ssnet-icon close pt-1"
                      style={{ width: "1em" }}
                      onClick={() =>
                        this.setState({ modal: !this.state.modal })
                      }
                      fill="#ea7525"
                    >
                      <path
                        className="st0"
                        d="M28,56C12.6,56,0,43.4,0,28S12.6,0,28,0s28,12.6,28,28S43.5,56,28,56z M28,4.7C15.2,4.7,4.8,15.2,4.8,28
	S15.2,51.2,28,51.2S51.3,40.8,51.3,28S40.8,4.7,28,4.7z"
                      />
                      <path
                        className="st0"
                        d="M39.4,41.7c-0.6,0-1.2-0.2-1.7-0.7L28,31.3L18.3,41C17.4,42,15.9,42,15,41c-0.5-0.5-0.7-1.1-0.7-1.7
	s0.2-1.2,0.7-1.7l9.7-9.7L15,18.3c-0.5-0.5-0.7-1.1-0.7-1.7c0-0.6,0.2-1.2,0.7-1.7c0.9-0.9,2.4-0.9,3.4,0l9.7,9.7l9.7-9.7
	c0.9-0.9,2.4-0.9,3.4,0c0.9,0.9,0.9,2.4,0,3.4L31.4,28l9.7,9.7c0.9,0.9,0.9,2.4,0,3.4C40.6,41.5,40,41.7,39.4,41.7z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <table className="table table-sm">
                  <tbody>
                    {Object.keys(this.state.selectedSite).map(
                      (k, i) =>
                        k !== "meters" ? (
                          <tr key={i}>
                            <th>{k}</th>
                            {k === "Phase" &&
                            configValue("ENABLE_EDITING", false) === "true" ? (
                              <td>
                                <select
                                  onChange={this.changePhaseOnState}
                                  value={this.state.selectedSite["Phase"]}
                                >
                                  {this.state.phases.map((p, i) => (
                                    <option key={i} value={p}>
                                      {p}
                                    </option>
                                  ))}
                                </select>
                              </td>
                            ) : (
                              <td>{this.state.selectedSite[k]}</td>
                            )}
                          </tr>
                        ) : null
                    )}
                  </tbody>
                </table>
              </div>
              <div className="col-12">
                <h3 className="text-primary">Meters</h3>
              </div>
              {this.state.selectedSite
                ? this.state.selectedSite.meters.map((meter, index) => (
                    <div className="col-6" key={index}>
                      <table className="table table-sm">
                        <tbody>
                          {Object.keys(meter).map((k, i) => (
                            <tr key={i}>
                              <th>{k}</th>
                              <td>{meter[k]}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ))
                : null}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

Content.contextType = FirebaseContext;

export default Content;
