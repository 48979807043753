import React, { Component } from "react";
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

class Filter extends Component {
  render() {
    return (
      <Dropdown
        style={this.props.style}
        className={`mr-2 ${this.props.className}`}
        isOpen={this.props.handleActive}
        toggle={this.props.toggle}
        size="sm"
      >
        <DropdownToggle
          className={`w-100 btn-block text-left special-caret__${
            this.props.handleActive ? "up" : "down"
          }`}
          color="primary"
          size="sm"
        >
          {this.props.title}
        </DropdownToggle>
        <DropdownMenu className="w-100 border-primary">
          {this.props.data.map((item, index) => (
            <DropdownItem
              className={`${
                this.props.selected.includes(item) ? "text-primary" : ""
              }`}
              onClick={this.props.handleSelect}
              key={index}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default Filter;
