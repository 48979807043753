/**
 * Configuration Lbrary
 *
 * this file exposes methods that allow safe access of
 * environment vars from within the NodeJS application
 */

/**
 * Global config Value
 *
 * returns a value form a given key in the app environment
 *
 * @param string key - the key identifier to look for in the ENV
 * @param string|Number|bool  defaultValue - the key identifier to look for in the ENV
 */
const configValue = (key = false, defaultValue = null) => {
  // validate the key
  if (!key) {
    throw new Error("configValue - Config key Not supplied");
  }
  // validate the default value
  if (defaultValue == null) {
    throw new Error("configValue - Config default Not supplied");
  }
  // try to get the key from the env
  const val = process.env["REACT_APP_" + key];
  // pass back the value because we can
  if (val == null) {
    // the value wasnt set so we should return the default
    return defaultValue;
  }
  return val;
};

// TODO move to .en
const firebaseConfig = {
  apiKey: process.env["REACT_APP_FIREBASE_API_KEY"],
  authDomain: process.env["REACT_APP_FIREBASE_AUTH_DOMAIN"],
  databaseURL: process.env["REACT_APP_FIREBASE_DATABASE_URL"]
};

// export our methods from here
export { firebaseConfig, configValue };
